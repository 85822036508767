<template>
  <div class="vd-text-align-right">
    <div class="vd-display-inline-block vms-video__filters--view">
      <text-popover
        tooltip-text="Thumbnail view"
        tooltip-icon="vd-icon-thumbnail-view-blue"
        tooltip-id="videosThumbnailViewDiv"
      ></text-popover>
    </div>
    <a
      class="vd-display-inline-block vms-video__filters--view"
      :href="vmsVideosLink"
    >
      <text-popover
        tooltip-text="List view"
        tooltip-icon="vd-icon-list-view-grey-circle"
        tooltip-id="videosListViewDiv"
      ></text-popover>
    </a>
  </div>
</template>

<script>
import AppConfig from '@configs/app-config'
import TextPopover from '@components/tooltips/text-popover'

export default {
  components: {
    TextPopover,
  },

  computed: {
    vmsVideosLink() {
      return `${AppConfig.vmsApiUrl}/videos`
    },
  },
}
</script>
