<template>
  <div v-if="!hideFilterList" class="vd-margin-bottom-20">
    <div
      v-if="filterLabel"
      class="vms-video__filters--list-label vd-h4 vd-margin-bottom-small"
      @click="toggleFilterListExpand"
    >
      <div class="row no-gutters">
        <div class="col-10">
          <div class="vd-h4">
            <strong>{{ filterLabel }}</strong>
          </div>
        </div>

        <div v-if="allowToggleFilterList" class="col-2 vd-text-align-right">
          <div
            :class="[
              'vms-video__filters--icon',
              isListExpanded ? 'vd-icon-minus-black' : 'vd-icon-plus-black',
            ]"
          ></div>
        </div>
      </div>
    </div>

    <div v-if="isListExpanded">
      <div class="vd-margin-bottom-small">
        <input
          v-if="showSearchBar"
          class="vd-full-width vd-border-light-grey vd-padding-small"
          type="text"
          placeholder="Search"
          @input="handleSearchBoxChange($event.currentTarget.value)"
        />
      </div>

      <div v-if="isFromSearch && !filtersList.length">No results found.</div>

      <div>
        <div
          v-for="filter in maxFilterListItems"
          :key="filter.label"
          class="vms-video__filters--list"
        >
          <filter-checkbox
            :id-prefix="filterIdPrefix"
            :label="filter.label"
            :value="filter.label"
            :checked="filter.isRefined"
            :count="filter.count"
            :is-radio-button="isRadioButton"
            @handle:checkbox-click="handleCheckBoxClick(filter.value)"
          ></filter-checkbox>
        </div>
      </div>

      <div
        v-if="canDisplaySeeAllOption"
        class="vd-h5"
        @click="toggleFilterListShowModal(filterLabel)"
      >
        <span class="vd-a vd-margin-right-small">See all</span>
        <span>+{{ remainingFilterLengthCount }}</span>
      </div>

      <filters-list-modal
        v-if="showFilterListModal && selectedFilterModal === filterLabel"
        :filter-label="filterLabel"
        :filters="filtersList"
        :is-from-search="isFromSearch"
        :label-option="labelOption"
        :show-search-bar="showSearchBar"
        @handle:checkbox-click="handleCheckBoxClick"
        @handle:search-change="handleSearchBoxChange"
      ></filters-list-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FiltersMixin from '@mixins/filters-mixin'
import FiltersListModal from '@views/videos/video-filters/filters-list-modal'
import FilterCheckbox from '@views/videos/video-filters/filter-checkbox'
import { slice, size } from 'lodash'

export default {
  components: {
    FiltersListModal,
    FilterCheckbox,
  },

  mixins: [FiltersMixin],

  props: {
    filterLabel: {
      type: String,
      required: false,
      default: '',
    },

    filterIdPrefix: {
      type: String,
      required: true,
      default: 'videoFilterOption',
    },

    filtersList: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },

    isFromSearch: {
      type: Boolean,
      require: false,
      default: false,
    },

    labelOption: {
      type: String,
      required: false,
      default: 'name',
    },

    allowToggleFilterList: {
      type: Boolean,
      required: false,
      default: true,
    },

    allowSeeAllOption: {
      type: Boolean,
      required: false,
      default: true,
    },

    showFilterListByDefault: {
      type: Boolean,
      required: false,
      default: false,
    },

    showSearchBar: {
      type: Boolean,
      required: false,
      default: true,
    },

    hasVideoCount: {
      type: Boolean,
      required: false,
      default: false,
    },

    maxFilterDisplaySize: {
      type: Number,
      required: false,
      default: 10,
    },

    maxNumberList: {
      type: Number,
      required: false,
      default: 4,
    },

    isRadioButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isListExpanded: this.showFilterListByDefault,
    }
  },

  computed: {
    ...mapGetters({
      showFilterListModal: 'videos/showFilterListModal',
      selectedFilterModal: 'videos/selectedFilterModal',
    }),

    hideFilterList() {
      return size(this.filtersList) <= 0 && !this.isFromSearch
    },

    maxFilterListItems() {
      return slice(this.filtersList, 0, this.maxFilterDisplaySize)
    },

    canDisplaySeeAllOption() {
      return (
        size(this.filtersList) > this.maxFilterDisplaySize &&
        this.allowSeeAllOption
      )
    },

    remainingFilterLengthCount() {
      return size(this.filtersList) - this.maxFilterDisplaySize
    },
  },

  methods: {
    ...mapActions({
      toggleFilterListShowModal: 'videos/toggleFilterListShowModal',
    }),

    toggleFilterListExpand() {
      if (this.allowToggleFilterList) {
        this.isListExpanded = !this.isListExpanded
      }
    },

    isEndOfNumberList(index) {
      return index >= this.maxNumberList
    },

    handleCheckBoxClick(value) {
      this.$emit('handle:checkbox-click', value)
    },

    handleSearchBoxChange(value) {
      this.$emit('handle:search-change', value)
    },
  },
}
</script>
