<template>
  <videos-layout>
    <ais-instant-search
      slot="content-body"
      index-name="videos_index"
      :search-client="searchClient"
      :routing="routing"
      :middlewares="middlewares"
      :class="[isMobileScreen ? '' : 'vms-content__container']"
    >
      <ais-configure
        :disjunctive-facets-refinements.camel="
          defaultDisjunctiveFacetsRefinements
        "
      />

      <div class="row no-gutters">
        <div class="col-12">
          <video-search></video-search>
        </div>
        <div ref="video-filters" class="vms-video__library--section-left">
          <div class="vd-padding-20">
            <div
              class="vd-h4 vms-video__filters--divider vd-border-light-grey vd-padding-bottom-medium vd-margin-bottom-medium"
            >
              <div class="row no-gutters">
                <div class="col-6">
                  <strong>Filtering by</strong>
                </div>
                <div class="col-6">
                  <ais-clear-refinements
                    :class-names="{
                      'ais-ClearRefinements-button--disabled':
                        'vms-video__filters--clear-all__disabled',
                    }"
                    :excluded-attributes="['public_video']"
                  >
                    <template v-slot="{ canRefine, refine }">
                      <div
                        v-if="canRefine"
                        class="vd-a vd-text-align-right"
                        @click.prevent="refine"
                      >
                        Clear all
                      </div>
                    </template>
                  </ais-clear-refinements>
                </div>
                <div class="col-12">
                  <ais-current-refinements
                    :excluded-attributes="['public_video']"
                  >
                    <template v-slot="{ items }">
                      <selected-filters :items="items"></selected-filters>
                    </template>
                  </ais-current-refinements>
                </div>
              </div>
            </div>

            <ais-range-input attribute="project_price">
              <template v-slot="{ currentRefinement, range, refine }">
                <range-min-max-slider
                  :default-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  :initial-selected-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  :current-refinement="currentRefinement"
                  :show-filter-list-by-default="true"
                  filter-label="Project price"
                  custom-input-container-class="vms-video__filters--range"
                  custom-input-range-class="vms-video__filters--input-number"
                  @handle:range-update="handleRangeUpdate($event, refine)"
                ></range-min-max-slider>
              </template>
            </ais-range-input>

            <ais-range-input attribute="project_production_hours">
              <template v-slot="{ currentRefinement, range, refine }">
                <range-min-max-slider
                  :default-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  :initial-selected-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  :current-refinement="currentRefinement"
                  :show-filter-list-by-default="true"
                  filter-label="Project production hours"
                  custom-input-container-class="vms-video__filters--range"
                  custom-input-range-class="vms-video__filters--input-number"
                  @handle:range-update="handleRangeUpdate($event, refine)"
                ></range-min-max-slider>
              </template>
            </ais-range-input>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="favourite_users"
              >
                <template v-slot="{ items, refine }">
                  <favourite-video-filter
                    :filters-list="items"
                    filter-id-prefix="favUserFilterOption"
                    @handle:checkbox-click="refine($event)"
                  ></favourite-video-filter>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel class="vd-margin-bottom-20">
              <ais-toggle-refinement
                attribute="is_starred"
                :on="1"
                label="Hero videos only"
              >
                <template v-slot="{ value, refine }">
                  <div class="vms-video__filters--list">
                    <filter-checkbox
                      label="Hero videos only"
                      :value="1"
                      :truncate-label="true"
                      :checked="value.isRefined"
                      :count="
                        value.onFacetValue.count ? value.onFacetValue.count : -1
                      "
                      :icon-class="`vd-icon-star-yellow vms-video__filters-checkbox--icon-bigger`"
                      @handle:checkbox-click="refine(value)"
                    >
                    </filter-checkbox>
                  </div>
                </template>
              </ais-toggle-refinement>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="product_type"
                searchable
              >
                <template
                  v-slot="{ items, refine, searchForItems, isFromSearch }"
                >
                  <filters-list
                    :filters-list="items"
                    :is-from-search="isFromSearch"
                    filter-label="Product type"
                    filter-id-prefix="productTypeFilterOption"
                    :show-filter-list-by-default="true"
                    :show-search-bar="true"
                    :max-filter-display-size="refinementListLimit"
                    @handle:checkbox-click="refine($event)"
                    @handle:search-change="searchForItems($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="style_types"
              >
                <template v-slot="{ items, refine }">
                  <filters-list
                    :filters-list="items"
                    filter-label="Video style"
                    filter-id-prefix="videoStyleFilterOption"
                    :show-search-bar="false"
                    :show-filter-list-by-default="true"
                    @handle:checkbox-click="refine($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="industry_type"
                searchable
              >
                <template
                  v-slot="{ items, refine, searchForItems, isFromSearch }"
                >
                  <filters-list
                    :filters-list="items"
                    :is-from-search="isFromSearch"
                    filter-label="Video industry"
                    filter-id-prefix="videoIndustryFilterOption"
                    :show-filter-list-by-default="true"
                    :show-search-bar="true"
                    @handle:checkbox-click="refine($event)"
                    @handle:search-change="searchForItems($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="office_name"
                searchable
              >
                <template
                  v-slot="{ items, refine, searchForItems, isFromSearch }"
                >
                  <filters-list
                    :filters-list="items"
                    :is-from-search="isFromSearch"
                    filter-label="Office name"
                    filter-id-prefix="officeNameFilterOption"
                    :show-filter-list-by-default="true"
                    :show-search-bar="true"
                    @handle:checkbox-click="refine($event)"
                    @handle:search-change="searchForItems($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="office_industry_type"
                searchable
              >
                <template
                  v-slot="{ items, refine, searchForItems, isFromSearch }"
                >
                  <filters-list
                    :filters-list="items"
                    :is-from-search="isFromSearch"
                    filter-label="Office industry"
                    filter-id-prefix="officeIndustryFilterOption"
                    :show-filter-list-by-default="true"
                    :show-search-bar="true"
                    @handle:checkbox-click="refine($event)"
                    @handle:search-change="searchForItems($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="project_producer"
                searchable
              >
                <template
                  v-slot="{ items, refine, searchForItems, isFromSearch }"
                >
                  <filters-list
                    :filters-list="items"
                    :is-from-search="isFromSearch"
                    filter-label="Project manager"
                    filter-id-prefix="projectProducerFilterOption"
                    :show-filter-list-by-default="true"
                    :show-search-bar="true"
                    @handle:checkbox-click="refine($event)"
                    @handle:search-change="searchForItems($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="project_director"
                searchable
              >
                <template
                  v-slot="{ items, refine, searchForItems, isFromSearch }"
                >
                  <filters-list
                    :filters-list="items"
                    :is-from-search="isFromSearch"
                    filter-label="Director"
                    filter-id-prefix="projectDirectorFilterOption"
                    :show-filter-list-by-default="true"
                    :show-search-bar="true"
                    @handle:checkbox-click="refine($event)"
                    @handle:search-change="searchForItems($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="project_account_manager"
                searchable
              >
                <template
                  v-slot="{ items, refine, searchForItems, isFromSearch }"
                >
                  <filters-list
                    :filters-list="items"
                    :is-from-search="isFromSearch"
                    filter-label="Relationship manager"
                    filter-id-prefix="projectAccountManagerFilterOption"
                    :show-filter-list-by-default="true"
                    :show-search-bar="true"
                    @handle:checkbox-click="refine($event)"
                    @handle:search-change="searchForItems($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="project_production_coordinator"
                searchable
              >
                <template
                  v-slot="{ items, refine, searchForItems, isFromSearch }"
                >
                  <filters-list
                    :filters-list="items"
                    :is-from-search="isFromSearch"
                    filter-label="Production coordinator"
                    filter-id-prefix="projectProductionCoordinatorFilterOption"
                    :show-filter-list-by-default="true"
                    :show-search-bar="true"
                    @handle:checkbox-click="refine($event)"
                    @handle:search-change="searchForItems($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="project_crew"
                searchable
              >
                <template
                  v-slot="{ items, refine, searchForItems, isFromSearch }"
                >
                  <filters-list
                    :filters-list="items"
                    :is-from-search="isFromSearch"
                    filter-label="Crew"
                    filter-id-prefix="projectCrewFilterOption"
                    :show-filter-list-by-default="true"
                    :show-search-bar="true"
                    @handle:checkbox-click="refine($event)"
                    @handle:search-change="searchForItems($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="project_shoot_crew_size"
              >
                <template v-slot="{ items, refine }">
                  <filters-list
                    :filters-list="items"
                    filter-label="Filming crew size"
                    filter-id-prefix="projectShootCrewSizeFilterOption"
                    :show-search-bar="false"
                    @handle:checkbox-click="refine($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="project_total_videos"
              >
                <template v-slot="{ items, refine }">
                  <filters-list
                    :filters-list="items"
                    filter-label="Project total videos"
                    filter-id-prefix="projectTotalVideosFilterOption"
                    :show-search-bar="false"
                    @handle:checkbox-click="refine($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="project_platform"
              >
                <template v-slot="{ items, refine }">
                  <filters-list
                    :filters-list="items"
                    filter-label="Primary platform"
                    filter-id-prefix="projectPlatformFilterOption"
                    :show-search-bar="false"
                    @handle:checkbox-click="refine($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="project_target_audience_types"
                searchable
              >
                <template
                  v-slot="{ items, refine, searchForItems, isFromSearch }"
                >
                  <filters-list
                    :filters-list="items"
                    :is-from-search="isFromSearch"
                    filter-label="Target audience"
                    filter-id-prefix="projectTargetAudienceFilterOption"
                    :show-search-bar="true"
                    @handle:checkbox-click="refine($event)"
                    @handle:search-change="searchForItems($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-numeric-menu attribute="duration" :items="durationItems">
                <template v-slot="{ items, refine }">
                  <filters-list
                    :filters-list="items"
                    filter-label="Duration"
                    filter-id-prefix="durationFilterOption"
                    :show-search-bar="false"
                    :is-radio-button="true"
                    @handle:checkbox-click="refine($event)"
                  ></filters-list>
                </template>
              </ais-numeric-menu>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="aspect_ratio"
              >
                <template v-slot="{ items, refine }">
                  <filters-list
                    :filters-list="items"
                    filter-label="Aspect ratio"
                    filter-id-prefix="aspectRatioFilterOption"
                    :show-search-bar="false"
                    @handle:checkbox-click="refine($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="resolution"
              >
                <template v-slot="{ items, refine }">
                  <filters-list
                    :filters-list="items"
                    filter-label="Resolution"
                    filter-id-prefix="resolutionFilterOption"
                    :show-search-bar="false"
                    @handle:checkbox-click="refine($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-range-input attribute="project_preproduction_hours">
              <template v-slot="{ currentRefinement, range, refine }">
                <range-min-max-slider
                  :default-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  :initial-selected-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  :current-refinement="currentRefinement"
                  filter-label="Pre-production hours"
                  custom-input-container-class="vms-video__filters--range"
                  custom-input-range-class="vms-video__filters--input-number"
                  @handle:range-update="handleRangeUpdate($event, refine)"
                ></range-min-max-slider>
              </template>
            </ais-range-input>

            <ais-range-input attribute="project_filming_hours">
              <template v-slot="{ currentRefinement, range, refine }">
                <range-min-max-slider
                  :default-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  :initial-selected-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  :current-refinement="currentRefinement"
                  filter-label="Filming hours"
                  custom-input-container-class="vms-video__filters--range"
                  custom-input-range-class="vms-video__filters--input-number"
                  @handle:range-update="handleRangeUpdate($event, refine)"
                ></range-min-max-slider>
              </template>
            </ais-range-input>

            <ais-range-input attribute="project_editing_hours">
              <template v-slot="{ currentRefinement, range, refine }">
                <range-min-max-slider
                  :default-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  :initial-selected-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  :current-refinement="currentRefinement"
                  filter-label="Editing hours"
                  custom-input-container-class="vms-video__filters--range"
                  custom-input-range-class="vms-video__filters--input-number"
                  @handle:range-update="handleRangeUpdate($event, refine)"
                ></range-min-max-slider>
              </template>
            </ais-range-input>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="project_disbursement"
                searchable
              >
                <template
                  v-slot="{ items, refine, searchForItems, isFromSearch }"
                >
                  <filters-list
                    :filters-list="items"
                    :is-from-search="isFromSearch"
                    filter-label="Disbursement"
                    filter-id-prefix="projectDisbursementFilterOption"
                    :show-search-bar="true"
                    @handle:checkbox-click="refine($event)"
                    @handle:search-change="searchForItems($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-range-input attribute="company_target">
              <template v-slot="{ currentRefinement, range, refine }">
                <range-min-max-slider
                  :default-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  :initial-selected-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  :current-refinement="currentRefinement"
                  filter-label="House accounts target"
                  custom-input-container-class="vms-video__filters--range"
                  custom-input-range-class="vms-video__filters--input-number"
                  @handle:range-update="handleRangeUpdate($event, refine)"
                ></range-min-max-slider>
              </template>
            </ais-range-input>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="office_yearly_objective_types"
              >
                <template v-slot="{ items, refine }">
                  <filters-list
                    :filters-list="items"
                    filter-label="Company objectives"
                    filter-id-prefix="companyObjectivesFilterOption"
                    :show-search-bar="false"
                    @handle:checkbox-click="refine($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-panel>
              <ais-refinement-list
                :limit="refinementListLimit"
                attribute="tags"
                searchable
              >
                <template
                  v-slot="{ items, refine, searchForItems, isFromSearch }"
                >
                  <filters-list
                    :filters-list="items"
                    :is-from-search="isFromSearch"
                    filter-label="Tags"
                    filter-id-prefix="projectTagsFilterOption"
                    :show-search-bar="true"
                    @handle:checkbox-click="refine($event)"
                    @handle:search-change="searchForItems($event)"
                  ></filters-list>
                </template>
              </ais-refinement-list>
            </ais-panel>

            <ais-range-input attribute="uploaded_timestamp">
              <template v-slot="{ range, refine }">
                <filter-date-range
                  date-picker-id="videoDateCreatedInput"
                  date-picker-label="Date created"
                  :default-range-value="{
                    min: range.min,
                    max: range.max,
                  }"
                  @handle:date-range-update="handleRangeUpdate($event, refine)"
                ></filter-date-range>
              </template>
            </ais-range-input>
          </div>

          <div
            ref="video-save-filters"
            class="vms-video__filters--footer vd-background-lightest-grey vd-border-light-grey vd-padding-medium vd-text-align-right"
          >
            <button
              id="toggleVideoLibraryFiltersButton"
              class="vd-btn-small vd-btn-blue"
              @click="handleToggleVideoLibraryFilters"
            >
              Save Filters
            </button>
          </div>
        </div>

        <div class="vms-video__library--section-right">
          <div class="vd-padding-20">
            <div class="vd-full-width vd-margin-bottom-medium">
              <div class="row no-gutters">
                <div class="col-6">
                  <ais-panel>
                    <ais-refinement-list attribute="public_video">
                      <template v-slot="{ items, refine }">
                        <video-privacy
                          :items="items"
                          @handle:toggle-privacy="refine(1)"
                        ></video-privacy>
                      </template>
                    </ais-refinement-list>
                  </ais-panel>
                </div>
                <div class="col-6">
                  <video-view-type />
                </div>
              </div>
            </div>
            <div class="vd-margin-bottom-medium">
              <ais-infinite-hits>
                <template v-slot="{ items, refineNext, sendEvent }">
                  <no-results v-if="!items.length"></no-results>
                  <div v-else>
                    <video-thumbnail
                      :videos="items"
                      @handle:project-click="
                        sendEvent('conversion', $event, 'Item Project Clicked')
                      "
                      @handle:copy-url="
                        sendEvent('conversion', $event, 'Item Recommended')
                      "
                      @handle:vlp-click="
                        sendEvent('conversion', $event, 'Item VLP Clicked')
                      "
                      @handle:copy-private="
                        sendEvent('conversion', $event, 'Item Shared')
                      "
                      @handle:item-click="
                        sendEvent('click', $event, 'Item Clicked')
                      "
                    ></video-thumbnail>
                    <div v-observe-visibility="refineNext"></div>
                  </div>
                </template>
              </ais-infinite-hits>
            </div>
          </div>
        </div>
      </div>
    </ais-instant-search>
  </videos-layout>
</template>

<script>
import { ObserveVisibility } from 'vue-observe-visibility'
import { history } from 'instantsearch.js/es/lib/routers'
import { mapActions, mapGetters } from 'vuex'
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares'
import algoliaAnalytics from 'search-insights'
import VideosLayout from '@/layouts/videos-layout'
import VideoSearch from '@views/videos/video-search/video-search'
import VideoPrivacy from '@views/videos/components/video-privacy'
import VideoViewType from '@views/videos/components/video-view-type'
import VideoThumbnail from '@views/videos/video-thumbnail/video-thumbnail'
import NoResults from '@views/videos/components/no-results'
import RangeMinMaxSlider from '@components/range-slider/range-min-max-slider'
import FiltersList from '@views/videos/video-filters/filters-list'
import FilterCheckbox from '@views/videos/video-filters/filter-checkbox'
import SelectedFilters from '@views/videos/video-filters/selected-filters'
import FilterDateRange from '@views/videos/video-filters/filter-date-range'
import FavouriteVideoFilter from '@views/videos/video-filters/favourite-video-filter'
import algoliaSearch from '@plugins/algolia'
import {
  AisInstantSearch,
  AisInfiniteHits,
  AisConfigure,
  AisPanel,
  AisRefinementList,
  AisRangeInput,
  AisClearRefinements,
  AisCurrentRefinements,
  AisNumericMenu,
  AisToggleRefinement,
} from 'vue-instantsearch'

// Don't initialize analytics on test env.
if (process.env.NODE_ENV !== 'test') {
  algoliaAnalytics('init', {
    appId: process.env.VUE_APP_ALGOLIA_APP_ID,
    apiKey: process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY,
  })
}

// Do not create insights client for test env.
const algoliaAnalyticsMiddleware = createInsightsMiddleware({
  insightsClient: process.env.NODE_ENV !== 'test' ? algoliaAnalytics : null,
})

export default {
  components: {
    VideosLayout,
    VideoSearch,
    VideoPrivacy,
    VideoViewType,
    VideoThumbnail,
    NoResults,
    FiltersList,
    FilterCheckbox,
    FilterDateRange,
    FavouriteVideoFilter,
    SelectedFilters,
    RangeMinMaxSlider,
    AisInstantSearch,
    AisInfiniteHits,
    AisConfigure,
    AisPanel,
    AisRefinementList,
    AisRangeInput,
    AisClearRefinements,
    AisCurrentRefinements,
    AisNumericMenu,
    AisToggleRefinement,
  },

  directives: {
    ObserveVisibility,
  },

  data() {
    return {
      defaultDisjunctiveFacetsRefinements: {},
      searchClient: algoliaSearch,
      middlewares: [algoliaAnalyticsMiddleware],
      refinementListLimit: 100000,
      routing: {
        router: history(),
        stateMapping: {
          // eslint-disable-next-line complexity
          stateToRoute(uiState) {
            const indexUiState = uiState['videos_index']
            return {
              q: indexUiState.query,
              project_price:
                indexUiState.range && indexUiState.range.project_price,
              project_production_hours:
                indexUiState.range &&
                indexUiState.range.project_production_hours,
              product_type:
                indexUiState.refinementList &&
                indexUiState.refinementList.product_type,
              style_types:
                indexUiState.refinementList &&
                indexUiState.refinementList.style_types,
              industry_type:
                indexUiState.refinementList &&
                indexUiState.refinementList.industry_type,
              office_name:
                indexUiState.refinementList &&
                indexUiState.refinementList.office_name,
              office_industry_type:
                indexUiState.refinementList &&
                indexUiState.refinementList.office_industry_type,
              project_producer:
                indexUiState.refinementList &&
                indexUiState.refinementList.project_producer,
              project_director:
                indexUiState.refinementList &&
                indexUiState.refinementList.project_director,
              project_account_manager:
                indexUiState.refinementList &&
                indexUiState.refinementList.project_account_manager,
              project_production_coordinator:
                indexUiState.refinementList &&
                indexUiState.refinementList.project_production_coordinator,
              project_crew:
                indexUiState.refinementList &&
                indexUiState.refinementList.project_crew,
              project_shoot_crew_size:
                indexUiState.refinementList &&
                indexUiState.refinementList.project_shoot_crew_size,
              project_total_videos:
                indexUiState.refinementList &&
                indexUiState.refinementList.project_total_videos,
              project_platform:
                indexUiState.refinementList &&
                indexUiState.refinementList.project_platform,
              tags:
                indexUiState.refinementList && indexUiState.refinementList.tags,
              project_target_audience_types:
                indexUiState.refinementList &&
                indexUiState.refinementList.project_target_audience_types,
              duration:
                indexUiState.numericMenu && indexUiState.numericMenu.duration,
              aspect_ratio:
                indexUiState.refinementList &&
                indexUiState.refinementList.aspect_ratio,
              resolution:
                indexUiState.refinementList &&
                indexUiState.refinementList.resolution,
              project_preproduction_hours:
                indexUiState.range &&
                indexUiState.range.project_preproduction_hours,
              project_filming_hours:
                indexUiState.range && indexUiState.range.project_filming_hours,
              project_editing_hours:
                indexUiState.range && indexUiState.range.project_editing_hours,
              project_disbursement:
                indexUiState.refinementList &&
                indexUiState.refinementList.project_disbursement,
              company_target:
                indexUiState.range && indexUiState.range.company_target,
              office_yearly_objective_types:
                indexUiState.refinementList &&
                indexUiState.refinementList.office_yearly_objective_types,
              uploaded_timestamp:
                indexUiState.range && indexUiState.range.uploaded_timestamp,
            }
          },
          routeToState(routeState) {
            return {
              ['videos_index']: {
                query: routeState.q,
                range: {
                  project_price: routeState.project_price,
                  project_production_hours: routeState.project_production_hours,
                  project_preproduction_hours:
                    routeState.project_preproduction_hours,
                  project_filming_hours: routeState.project_filming_hours,
                  project_editing_hours: routeState.project_editing_hours,
                  company_target: routeState.company_target,
                  uploaded_timestamp: routeState.uploaded_timestamp,
                },
                refinementList: {
                  product_type: routeState.product_type,
                  style_types: routeState.style_types,
                  industry_type: routeState.industry_type,
                  office_name: routeState.office_name,
                  office_industry_type: routeState.office_industry_type,
                  project_producer: routeState.project_producer,
                  project_director: routeState.project_director,
                  project_account_manager: routeState.project_account_manager,
                  project_production_coordinator:
                    routeState.project_production_coordinator,
                  project_crew: routeState.project_crew,
                  project_shoot_crew_size: routeState.project_shoot_crew_size,
                  project_total_videos: routeState.project_total_videos,
                  project_platform: routeState.project_platform,
                  tags: routeState.tags,
                  project_target_audience_types:
                    routeState.project_target_audience_types,
                  aspect_ratio: routeState.aspect_ratio,
                  resolution: routeState.resolution,
                  office_yearly_objective_types:
                    routeState.office_yearly_objective_types,
                  project_disbursement: routeState.project_disbursement,
                },
                numericMenu: {
                  duration: routeState.duration,
                },
              },
            }
          },
        },
      },
    }
  },

  computed: {
    ...mapGetters({
      loggedInUser: 'auth/getVmsUserData',
      showVideoLibraryFilters: 'videos/showVideoLibraryFilters',
      isMobileScreen: 'common/isMobileScreen',
    }),

    durationItems() {
      return [
        { label: '<= 10 seconds', end: 10 },
        { label: '11 - 30 seconds', start: 11, end: 30 },
        { label: '31 - 60 seconds', start: 31, end: 60 },
        { label: '61 - 90 seconds', start: 61, end: 90 },
        { label: '91 - 120 seconds', start: 91, end: 120 },
        { label: '2 - 3 minutes', start: 120, end: 180 },
        { label: '3 - 4 minutes', start: 180, end: 240 },
        { label: '4 - 5 minutes', start: 240, end: 300 },
        { label: '5+ minutes', start: 300 },
        { label: '1+ hour', start: 3600 },
      ]
    },

    showFilters() {
      return !this.isMobileScreen || this.showVideoLibraryFilters
    },
  },

  watch: {
    'loggedInUser.id': (val) => {
      if (val) {
        algoliaAnalytics('setUserToken', val.toString())
      }
    },

    showFilters() {
      this.toggleFilters()
    },

    isMobileScreen() {
      this.toggleFilters()
    },
  },

  mounted() {
    // Commenting this instead of deleting for future usage.
    // if (size(this.$route.query) <= 0) {
    //   this.defaultDisjunctiveFacetsRefinements = {
    //     product_name: ['Edit', 'Animation'],
    //   }
    // }

    this.toggleFilters()
  },

  methods: {
    ...mapActions({
      toggleVideoLibraryFiltersShow: 'videos/toggleVideoLibraryFiltersShow',
    }),

    handleRangeUpdate($event, refine) {
      refine({ min: $event.min, max: $event.max })
    },

    handleToggleVideoLibraryFilters() {
      window.scrollTo({
        top: 0,
      })

      this.toggleVideoLibraryFiltersShow()
    },

    // Changing attribute using dynamic props inside this component will cause algolia search to re-render.
    // To prevent this we will use ref to add and remove attributes manually
    toggleFilters() {
      const videoFilters = this.$refs['video-filters']

      if (videoFilters) {
        if (this.showFilters) {
          videoFilters.style = ''
        } else {
          videoFilters.style.display = 'none'
        }
      }

      const videoSaveFilters = this.$refs['video-save-filters']
      if (this.isMobileScreen) {
        videoSaveFilters.style = ''
      } else {
        videoSaveFilters.style.display = 'none'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@styles/views/videos/video-library';
@import '@styles/views/videos/video-search';
</style>
