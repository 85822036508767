<template>
  <div class="vd-margin-bottom-20">
    <div
      v-if="filterLabel"
      class="vms-video__filters--range-label vd-h4"
      @click="toggleFilterListExpand"
    >
      <div class="row no-gutters">
        <div class="col-10">
          <div class="vd-h4">
            <strong>{{ filterLabel }}</strong>
          </div>
        </div>

        <div v-if="allowToggleFilterList" class="col-2 vd-text-align-right">
          <div
            :class="[
              'vms-video__filters--icon',
              isListExpanded ? 'vd-icon-minus-black' : 'vd-icon-plus-black',
            ]"
          ></div>
        </div>
      </div>
    </div>

    <div v-if="isListExpanded">
      <v-range-slider
        :value="selectedRange"
        :min="defaultRangeValue.min"
        :max="defaultRangeValue.max"
        :step="rangeStep"
        :ticks="ticks"
        :tick-size="tickSize"
        :hide-details="hideDetails"
        :track-color="trackColor"
        :track-fill-color="trackFillColor"
        :color="color"
        @change="updateRangeValue"
      >
      </v-range-slider>

      <div :class="customInputContainerClass">
        <div class="row no-gutters">
          <div class="col-6">
            <div class="vd-display-inline-block">
              <input
                v-model.number="range.min"
                :class="[
                  'vd-border-light-grey vd-padding-extra-small',
                  customInputRangeClass,
                ]"
                type="number"
              />
            </div>
          </div>
          <div class="col-6 vd-text-align-right">
            <div class="vms-video__filters--range-max vd-display-inline-block">
              <input
                v-model.number="range.max"
                :class="[
                  'vd-border-light-grey vd-padding-extra-small',
                  customInputRangeClass,
                ]"
                type="number"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetify from 'vuetify'
import { isEqual } from 'lodash'

export default {
  vuetify: new Vuetify(),

  props: {
    currentRefinement: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    filterLabel: {
      type: String,
      required: false,
      default: '',
    },

    defaultRangeValue: {
      type: Object,
      required: false,
      default: () => {
        return {
          min: 1,
          max: 20,
        }
      },
    },

    initialSelectedRangeValue: {
      type: Object,
      required: false,
      default: () => {
        return {
          min: 1,
          max: 5,
        }
      },
    },

    rangeStep: {
      type: Number,
      required: false,
      default: 1,
    },

    ticks: {
      type: [Boolean, String],
      required: false,
      default: false,
    },

    tickSize: {
      type: Number,
      required: false,
      default: 3,
    },

    hideDetails: {
      type: Boolean,
      required: false,
      default: true,
    },

    trackColor: {
      type: String,
      required: false,
      default: '#e6e6e6',
    },

    trackFillColor: {
      type: String,
      required: false,
      default: '#00c5ff',
    },

    color: {
      type: String,
      required: false,
      default: '#00c5ff',
    },

    customInputContainerClass: {
      type: String,
      required: false,
      default: '',
    },

    customInputRangeClass: {
      type: String,
      required: false,
      default: '',
    },

    allowToggleFilterList: {
      type: Boolean,
      required: false,
      default: true,
    },

    showFilterListByDefault: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      range: {
        min: this.initialSelectedRangeValue.min,
        max: this.initialSelectedRangeValue.max,
      },
      isListExpanded: this.showFilterListByDefault,
    }
  },

  computed: {
    selectedRange() {
      return [this.range.min, this.range.max]
    },
  },

  watch: {
    range: {
      handler: function (newVal) {
        this.$emit('handle:range-update', newVal)
      },
      deep: true,
    },

    defaultRangeValue(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.range.min = newVal.min
        this.range.max = newVal.max
      }
    },

    currentRefinement(currentRange) {
      this.range.min = currentRange.min
        ? currentRange.min
        : this.defaultRangeValue.min
      this.range.max = currentRange.max
        ? currentRange.max
        : this.defaultRangeValue.max
    },
  },

  methods: {
    updateRangeValue(rangeValue) {
      this.range.min = rangeValue[0]
      this.range.max = rangeValue[1]
    },

    toggleFilterListExpand() {
      if (this.allowToggleFilterList) {
        this.isListExpanded = !this.isListExpanded
      }
    },
  },
}
</script>
