<template>
  <div class="vd-margin-bottom-20">
    <div
      v-if="datePickerLabel"
      class="vms-video__filters--date-label vd-h4 vd-margin-bottom-small"
      @click="toggleFilterListExpand"
    >
      <div class="row no-gutters">
        <div class="col-10">
          <div class="vd-h4">
            <strong>{{ datePickerLabel }}</strong>
          </div>
        </div>

        <div v-if="allowToggleFilterList" class="col-2 vd-text-align-right">
          <div
            :class="[
              'vms-video__filters--icon',
              isListExpanded ? 'vd-icon-minus-black' : 'vd-icon-plus-black',
            ]"
          ></div>
        </div>
      </div>
    </div>

    <div v-if="isListExpanded">
      <flat-pickr
        :id="datePickerId"
        v-model="selectedDates"
        :config="datePickerConfig"
        :class="['vd-full-width', datePickerCustomClass]"
        :placeholder="datePickerPlaceholder"
        :name="datePickerId"
      >
      </flat-pickr>
    </div>
  </div>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'
import { split, size, isEqual } from 'lodash'

export default {
  components: {
    FlatPickr,
  },

  props: {
    datePickerId: {
      type: String,
      required: true,
      default: 'dateCreatedInput',
    },

    datePickerLabel: {
      type: String,
      required: false,
      default: 'Created at',
    },

    datePickerConfig: {
      type: Object,
      required: false,
      default: () => {
        return {
          mode: 'range',
          enableTime: false,
          allowInput: true,
          altFormat: 'd-m-Y',
          dateFormat: 'U',
          altInput: true,
          disableMobile: 'true',
        }
      },
    },

    datePickerCustomClass: {
      type: String,
      required: false,
      default:
        'vd-border-light-grey vd-background-transparent vd-padding-small',
    },

    datePickerPlaceholder: {
      type: String,
      required: false,
      default: 'Select dates',
    },

    defaultRangeValue: {
      type: Object,
      required: true,
      default: () => {
        return {
          min: 0,
          max: 100,
        }
      },
    },

    allowToggleFilterList: {
      type: Boolean,
      required: false,
      default: true,
    },

    showFilterListByDefault: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      selectedDates: null,
      isListExpanded: this.showFilterListByDefault,
    }
  },

  watch: {
    selectedDates(val) {
      let splitString = split(val, ' to ')
      if (size(splitString) === 2) {
        let chosenMinRange = parseInt(splitString[0])
        let chosenMaxRange = parseInt(splitString[1])

        this.$emit('handle:date-range-update', {
          min:
            chosenMinRange < this.defaultRangeValue.min
              ? this.defaultRangeValue.min
              : chosenMinRange,
          max:
            chosenMaxRange > this.defaultRangeValue.max
              ? this.defaultRangeValue.max
              : chosenMaxRange,
        })
      } else {
        this.$emit('handle:date-range-update', this.defaultRangeValue)
      }
    },

    defaultRangeValue(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        if (newVal.min < oldVal.min || newVal.max > oldVal.max) {
          this.selectedDates = null
        }
      }
    },
  },

  methods: {
    toggleFilterListExpand() {
      if (this.allowToggleFilterList) {
        this.isListExpanded = !this.isListExpanded
      }
    },
  },
}
</script>

<style>
@import '~flatpickr/dist/flatpickr.css';
</style>
