<template>
  <div class="vms-video__search vd-background-blue">
    <div
      :class="[
        'vms-video__search--container vd-padding-left-20 vd-padding-right-20 vd-padding-top-50',
        isMobileScreen ? 'vd-padding-bottom-50' : 'vd-padding-bottom-90',
      ]"
    >
      <div
        :class="[
          'vd-padding-bottom-30 vd-white',
          isMobileScreen ? 'vd-h2' : 'vd-h1',
        ]"
      >
        <strong>Video Library</strong>
      </div>
      <div class="row no-gutters vd-position-relative">
        <div v-click-outside="hideDropDown" class="vms-video__search--wrapper">
          <ais-search-box>
            <template v-slot="{ refine }">
              <input
                v-model="searchInput"
                :class="[
                  isDropdownShown
                    ? 'vms-video__search--active'
                    : 'vd-border-radius-6',
                  'vms-video__search--input vd-h4 vd-background-white vd-padding-top-10 vd-padding-bottom-10 vd-padding-right-small vd-padding-left-50',
                ]"
                type="text"
                placeholder="Search"
                @input="refine($event.currentTarget.value)"
                @click="showDropDown"
              />
            </template>
          </ais-search-box>
          <div
            v-show="isDropdownShown"
            :class="[
              'vms-video__search--extendable vd-position-absolute vd-border-light-grey vd-background-white vd-full-width',
              isMobileScreen ? 'vd-margin-top-extra-small' : '',
            ]"
          >
            <div
              class="vd-position-relative vd-padding-left-30 vd-padding-right-30 vd-padding-top-20 vd-padding-bottom-20"
            >
              <div
                class="vd-icon-close vd-position-absolute vms-video__search--icon-close"
                @click="hideDropDown"
              ></div>
              <div class="row">
                <div :class="[isMobileScreen ? 'col-12' : 'col-6']">
                  <ais-panel>
                    <ais-refinement-list
                      :limit="refinementListLimit"
                      attribute="product_type"
                      searchable
                    >
                      <template v-slot="{ items, refine, searchForItems }">
                        <div class="vd-margin-bottom-20">
                          <filters-list
                            :filters-list="items"
                            filter-type="productTypes"
                            filter-label="Product type"
                            filter-id-prefix="productTypeFilterOption"
                            :allow-toggle-filter-list="false"
                            :show-filter-list-by-default="true"
                            :show-search-bar="false"
                            :max-filter-display-size="refinementListLimit"
                            @handle:checkbox-click="refine($event)"
                            @handle:search-change="searchForItems($event)"
                          ></filters-list>
                        </div>
                      </template>
                    </ais-refinement-list>
                  </ais-panel>
                </div>
                <div :class="[isMobileScreen ? 'col-12' : 'col-6']">
                  <ais-range-input attribute="project_price">
                    <template v-slot="{ currentRefinement, range, refine }">
                      <range-min-max-slider
                        :default-range-value="{
                          min: range.min,
                          max: range.max,
                        }"
                        :initial-selected-range-value="{
                          min: range.min,
                          max: range.max,
                        }"
                        :current-refinement="currentRefinement"
                        :allow-toggle-filter-list="false"
                        :show-filter-list-by-default="true"
                        filter-label="Project price"
                        custom-input-container-class="vms-video__filters--range"
                        custom-input-range-class="vms-video__filters--input-number"
                        @handle:range-update="handleRangeUpdate($event, refine)"
                      ></range-min-max-slider>
                    </template>
                  </ais-range-input>

                  <ais-range-input attribute="project_production_hours">
                    <template v-slot="{ currentRefinement, range, refine }">
                      <range-min-max-slider
                        :default-range-value="{
                          min: range.min,
                          max: range.max,
                        }"
                        :initial-selected-range-value="{
                          min: range.min,
                          max: range.max,
                        }"
                        :current-refinement="currentRefinement"
                        :allow-toggle-filter-list="false"
                        :show-filter-list-by-default="true"
                        filter-label="Project production hours"
                        custom-input-container-class="vms-video__filters--range"
                        custom-input-range-class="vms-video__filters--input-number"
                        @handle:range-update="handleRangeUpdate($event, refine)"
                      ></range-min-max-slider>
                    </template>
                  </ais-range-input>

                  <ais-panel>
                    <ais-refinement-list
                      :limit="refinementListLimit"
                      attribute="aspect_ratio"
                    >
                      <template v-slot="{ items, refine }">
                        <filters-list
                          :filters-list="items"
                          filter-label="Aspect ratio"
                          filter-id-prefix="aspectRatioFilterOption"
                          :allow-toggle-filter-list="false"
                          :allow-show-all="false"
                          :show-filter-list-by-default="true"
                          :show-search-bar="false"
                          :max-filter-display-size="4"
                          @handle:checkbox-click="refine($event)"
                        ></filters-list>
                      </template>
                    </ais-refinement-list>
                  </ais-panel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          v-if="isMobileScreen"
          id="toggleVideoLibraryFiltersButton"
          class="vms-video__search--btn-filter vd-border-width-0 vd-padding-top-small vd-padding-bottom-small vd-padding-right-medium vd-padding-left-medium vd-background-light-blue vd-border-light-blue vd-border-radius-5 vd-margin-left-extra-small"
          @click="toggleVideoLibraryFiltersShow"
        >
          <span
            :class="[
              'vms-video__search--btn-filter__icon',
              showVideoLibraryFilters
                ? 'vd-icon-close-white'
                : 'vd-icon-filter-white',
            ]"
          ></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import RangeMinMaxSlider from '@components/range-slider/range-min-max-slider'
import FiltersList from '@views/videos/video-filters/filters-list'
import {
  AisPanel,
  AisRefinementList,
  AisRangeInput,
  AisSearchBox,
} from 'vue-instantsearch'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    RangeMinMaxSlider,
    FiltersList,
    AisPanel,
    AisRefinementList,
    AisRangeInput,
    AisSearchBox,
  },

  directives: {
    ClickOutside,
  },

  data() {
    return {
      searchInput: '',
      isDropdownShown: false,
      refinementListLimit: 100000,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      showVideoLibraryFilters: 'videos/showVideoLibraryFilters',
    }),
  },

  methods: {
    ...mapActions({
      toggleVideoLibraryFiltersShow: 'videos/toggleVideoLibraryFiltersShow',
    }),

    hideDropDown() {
      this.isDropdownShown = false
    },

    showDropDown() {
      this.isDropdownShown = true
    },

    handleRangeUpdate($event, refine) {
      refine({ min: $event.min, max: $event.max })
    },
  },
}
</script>
