<template>
  <div class="vd-margin-bottom-10">
    <div
      v-for="filter in loggedInUserFilter"
      :key="filter.label"
      class="vms-video__filters--list"
    >
      <filter-checkbox
        :id-prefix="filterIdPrefix"
        label="Favourite videos"
        :value="filter.label"
        :checked="filter.isRefined"
        :count="filter.count"
        :is-radio-button="isRadioButton"
        :icon-class="`vd-icon-heart-red`"
        @handle:checkbox-click="handleCheckBoxClick(filter.value)"
      ></filter-checkbox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { filter } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'
import FilterCheckbox from '@views/videos/video-filters/filter-checkbox'

export default {
  components: {
    FilterCheckbox,
  },

  mixins: [FiltersMixin],

  props: {
    filterIdPrefix: {
      type: String,
      required: true,
      default: 'videoFilterOption',
    },

    filtersList: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },

    isRadioButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      loggedInUser: 'auth/getVmsUserData',
    }),

    loggedInUserFilter() {
      if (this.loggedInUser.id) {
        return filter(this.filtersList, (filterList) => {
          return filterList.value.toString() === this.loggedInUser.id.toString()
        })
      }
      return []
    },
  },

  methods: {
    handleCheckBoxClick(value) {
      this.$emit('handle:checkbox-click', value)
    },
  },
}
</script>
