var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('videos-layout',[_c('ais-instant-search',{class:[_vm.isMobileScreen ? '' : 'vms-content__container'],attrs:{"slot":"content-body","index-name":"videos_index","search-client":_vm.searchClient,"routing":_vm.routing,"middlewares":_vm.middlewares},slot:"content-body"},[_c('ais-configure',{attrs:{"disjunctiveFacetsRefinements":_vm.defaultDisjunctiveFacetsRefinements}}),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12"},[_c('video-search')],1),_c('div',{ref:"video-filters",staticClass:"vms-video__library--section-left"},[_c('div',{staticClass:"vd-padding-20"},[_c('div',{staticClass:"vd-h4 vms-video__filters--divider vd-border-light-grey vd-padding-bottom-medium vd-margin-bottom-medium"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-6"},[_c('strong',[_vm._v("Filtering by")])]),_c('div',{staticClass:"col-6"},[_c('ais-clear-refinements',{attrs:{"class-names":{
                    'ais-ClearRefinements-button--disabled':
                      'vms-video__filters--clear-all__disabled',
                  },"excluded-attributes":['public_video']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var canRefine = ref.canRefine;
                  var refine = ref.refine;
return [(canRefine)?_c('div',{staticClass:"vd-a vd-text-align-right",on:{"click":function($event){$event.preventDefault();return refine($event)}}},[_vm._v(" Clear all ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"col-12"},[_c('ais-current-refinements',{attrs:{"excluded-attributes":['public_video']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var items = ref.items;
return [_c('selected-filters',{attrs:{"items":items}})]}}])})],1)])]),_c('ais-range-input',{attrs:{"attribute":"project_price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var currentRefinement = ref.currentRefinement;
                  var range = ref.range;
                  var refine = ref.refine;
return [_c('range-min-max-slider',{attrs:{"default-range-value":{
                  min: range.min,
                  max: range.max,
                },"initial-selected-range-value":{
                  min: range.min,
                  max: range.max,
                },"current-refinement":currentRefinement,"show-filter-list-by-default":true,"filter-label":"Project price","custom-input-container-class":"vms-video__filters--range","custom-input-range-class":"vms-video__filters--input-number"},on:{"handle:range-update":function($event){return _vm.handleRangeUpdate($event, refine)}}})]}}])}),_c('ais-range-input',{attrs:{"attribute":"project_production_hours"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var currentRefinement = ref.currentRefinement;
                var range = ref.range;
                var refine = ref.refine;
return [_c('range-min-max-slider',{attrs:{"default-range-value":{
                  min: range.min,
                  max: range.max,
                },"initial-selected-range-value":{
                  min: range.min,
                  max: range.max,
                },"current-refinement":currentRefinement,"show-filter-list-by-default":true,"filter-label":"Project production hours","custom-input-container-class":"vms-video__filters--range","custom-input-range-class":"vms-video__filters--input-number"},on:{"handle:range-update":function($event){return _vm.handleRangeUpdate($event, refine)}}})]}}])}),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"favourite_users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
return [_c('favourite-video-filter',{attrs:{"filters-list":items,"filter-id-prefix":"favUserFilterOption"},on:{"handle:checkbox-click":function($event){return refine($event)}}})]}}])})],1),_c('ais-panel',{staticClass:"vd-margin-bottom-20"},[_c('ais-toggle-refinement',{attrs:{"attribute":"is_starred","on":1,"label":"Hero videos only"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var value = ref.value;
                var refine = ref.refine;
return [_c('div',{staticClass:"vms-video__filters--list"},[_c('filter-checkbox',{attrs:{"label":"Hero videos only","value":1,"truncate-label":true,"checked":value.isRefined,"count":value.onFacetValue.count ? value.onFacetValue.count : -1,"icon-class":"vd-icon-star-yellow vms-video__filters-checkbox--icon-bigger"},on:{"handle:checkbox-click":function($event){return refine(value)}}})],1)]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"product_type","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var searchForItems = ref.searchForItems;
                var isFromSearch = ref.isFromSearch;
return [_c('filters-list',{attrs:{"filters-list":items,"is-from-search":isFromSearch,"filter-label":"Product type","filter-id-prefix":"productTypeFilterOption","show-filter-list-by-default":true,"show-search-bar":true,"max-filter-display-size":_vm.refinementListLimit},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"style_types"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
return [_c('filters-list',{attrs:{"filters-list":items,"filter-label":"Video style","filter-id-prefix":"videoStyleFilterOption","show-search-bar":false,"show-filter-list-by-default":true},on:{"handle:checkbox-click":function($event){return refine($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"industry_type","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var searchForItems = ref.searchForItems;
                var isFromSearch = ref.isFromSearch;
return [_c('filters-list',{attrs:{"filters-list":items,"is-from-search":isFromSearch,"filter-label":"Video industry","filter-id-prefix":"videoIndustryFilterOption","show-filter-list-by-default":true,"show-search-bar":true},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"office_name","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var searchForItems = ref.searchForItems;
                var isFromSearch = ref.isFromSearch;
return [_c('filters-list',{attrs:{"filters-list":items,"is-from-search":isFromSearch,"filter-label":"Office name","filter-id-prefix":"officeNameFilterOption","show-filter-list-by-default":true,"show-search-bar":true},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"office_industry_type","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var searchForItems = ref.searchForItems;
                var isFromSearch = ref.isFromSearch;
return [_c('filters-list',{attrs:{"filters-list":items,"is-from-search":isFromSearch,"filter-label":"Office industry","filter-id-prefix":"officeIndustryFilterOption","show-filter-list-by-default":true,"show-search-bar":true},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"project_producer","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var searchForItems = ref.searchForItems;
                var isFromSearch = ref.isFromSearch;
return [_c('filters-list',{attrs:{"filters-list":items,"is-from-search":isFromSearch,"filter-label":"Project manager","filter-id-prefix":"projectProducerFilterOption","show-filter-list-by-default":true,"show-search-bar":true},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"project_director","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var searchForItems = ref.searchForItems;
                var isFromSearch = ref.isFromSearch;
return [_c('filters-list',{attrs:{"filters-list":items,"is-from-search":isFromSearch,"filter-label":"Director","filter-id-prefix":"projectDirectorFilterOption","show-filter-list-by-default":true,"show-search-bar":true},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"project_account_manager","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var searchForItems = ref.searchForItems;
                var isFromSearch = ref.isFromSearch;
return [_c('filters-list',{attrs:{"filters-list":items,"is-from-search":isFromSearch,"filter-label":"Relationship manager","filter-id-prefix":"projectAccountManagerFilterOption","show-filter-list-by-default":true,"show-search-bar":true},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"project_production_coordinator","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var searchForItems = ref.searchForItems;
                var isFromSearch = ref.isFromSearch;
return [_c('filters-list',{attrs:{"filters-list":items,"is-from-search":isFromSearch,"filter-label":"Production coordinator","filter-id-prefix":"projectProductionCoordinatorFilterOption","show-filter-list-by-default":true,"show-search-bar":true},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"project_crew","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var searchForItems = ref.searchForItems;
                var isFromSearch = ref.isFromSearch;
return [_c('filters-list',{attrs:{"filters-list":items,"is-from-search":isFromSearch,"filter-label":"Crew","filter-id-prefix":"projectCrewFilterOption","show-filter-list-by-default":true,"show-search-bar":true},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"project_shoot_crew_size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
return [_c('filters-list',{attrs:{"filters-list":items,"filter-label":"Filming crew size","filter-id-prefix":"projectShootCrewSizeFilterOption","show-search-bar":false},on:{"handle:checkbox-click":function($event){return refine($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"project_total_videos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
return [_c('filters-list',{attrs:{"filters-list":items,"filter-label":"Project total videos","filter-id-prefix":"projectTotalVideosFilterOption","show-search-bar":false},on:{"handle:checkbox-click":function($event){return refine($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"project_platform"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
return [_c('filters-list',{attrs:{"filters-list":items,"filter-label":"Primary platform","filter-id-prefix":"projectPlatformFilterOption","show-search-bar":false},on:{"handle:checkbox-click":function($event){return refine($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"project_target_audience_types","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var searchForItems = ref.searchForItems;
                var isFromSearch = ref.isFromSearch;
return [_c('filters-list',{attrs:{"filters-list":items,"is-from-search":isFromSearch,"filter-label":"Target audience","filter-id-prefix":"projectTargetAudienceFilterOption","show-search-bar":true},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-numeric-menu',{attrs:{"attribute":"duration","items":_vm.durationItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
return [_c('filters-list',{attrs:{"filters-list":items,"filter-label":"Duration","filter-id-prefix":"durationFilterOption","show-search-bar":false,"is-radio-button":true},on:{"handle:checkbox-click":function($event){return refine($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"aspect_ratio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
return [_c('filters-list',{attrs:{"filters-list":items,"filter-label":"Aspect ratio","filter-id-prefix":"aspectRatioFilterOption","show-search-bar":false},on:{"handle:checkbox-click":function($event){return refine($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"resolution"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
return [_c('filters-list',{attrs:{"filters-list":items,"filter-label":"Resolution","filter-id-prefix":"resolutionFilterOption","show-search-bar":false},on:{"handle:checkbox-click":function($event){return refine($event)}}})]}}])})],1),_c('ais-range-input',{attrs:{"attribute":"project_preproduction_hours"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var currentRefinement = ref.currentRefinement;
                var range = ref.range;
                var refine = ref.refine;
return [_c('range-min-max-slider',{attrs:{"default-range-value":{
                  min: range.min,
                  max: range.max,
                },"initial-selected-range-value":{
                  min: range.min,
                  max: range.max,
                },"current-refinement":currentRefinement,"filter-label":"Pre-production hours","custom-input-container-class":"vms-video__filters--range","custom-input-range-class":"vms-video__filters--input-number"},on:{"handle:range-update":function($event){return _vm.handleRangeUpdate($event, refine)}}})]}}])}),_c('ais-range-input',{attrs:{"attribute":"project_filming_hours"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var currentRefinement = ref.currentRefinement;
                var range = ref.range;
                var refine = ref.refine;
return [_c('range-min-max-slider',{attrs:{"default-range-value":{
                  min: range.min,
                  max: range.max,
                },"initial-selected-range-value":{
                  min: range.min,
                  max: range.max,
                },"current-refinement":currentRefinement,"filter-label":"Filming hours","custom-input-container-class":"vms-video__filters--range","custom-input-range-class":"vms-video__filters--input-number"},on:{"handle:range-update":function($event){return _vm.handleRangeUpdate($event, refine)}}})]}}])}),_c('ais-range-input',{attrs:{"attribute":"project_editing_hours"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var currentRefinement = ref.currentRefinement;
                var range = ref.range;
                var refine = ref.refine;
return [_c('range-min-max-slider',{attrs:{"default-range-value":{
                  min: range.min,
                  max: range.max,
                },"initial-selected-range-value":{
                  min: range.min,
                  max: range.max,
                },"current-refinement":currentRefinement,"filter-label":"Editing hours","custom-input-container-class":"vms-video__filters--range","custom-input-range-class":"vms-video__filters--input-number"},on:{"handle:range-update":function($event){return _vm.handleRangeUpdate($event, refine)}}})]}}])}),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"project_disbursement","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var searchForItems = ref.searchForItems;
                var isFromSearch = ref.isFromSearch;
return [_c('filters-list',{attrs:{"filters-list":items,"is-from-search":isFromSearch,"filter-label":"Disbursement","filter-id-prefix":"projectDisbursementFilterOption","show-search-bar":true},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})]}}])})],1),_c('ais-range-input',{attrs:{"attribute":"company_target"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var currentRefinement = ref.currentRefinement;
                var range = ref.range;
                var refine = ref.refine;
return [_c('range-min-max-slider',{attrs:{"default-range-value":{
                  min: range.min,
                  max: range.max,
                },"initial-selected-range-value":{
                  min: range.min,
                  max: range.max,
                },"current-refinement":currentRefinement,"filter-label":"House accounts target","custom-input-container-class":"vms-video__filters--range","custom-input-range-class":"vms-video__filters--input-number"},on:{"handle:range-update":function($event){return _vm.handleRangeUpdate($event, refine)}}})]}}])}),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"office_yearly_objective_types"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
return [_c('filters-list',{attrs:{"filters-list":items,"filter-label":"Company objectives","filter-id-prefix":"companyObjectivesFilterOption","show-search-bar":false},on:{"handle:checkbox-click":function($event){return refine($event)}}})]}}])})],1),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"tags","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
                var searchForItems = ref.searchForItems;
                var isFromSearch = ref.isFromSearch;
return [_c('filters-list',{attrs:{"filters-list":items,"is-from-search":isFromSearch,"filter-label":"Tags","filter-id-prefix":"projectTagsFilterOption","show-search-bar":true},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})]}}])})],1),_c('ais-range-input',{attrs:{"attribute":"uploaded_timestamp"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var range = ref.range;
                var refine = ref.refine;
return [_c('filter-date-range',{attrs:{"date-picker-id":"videoDateCreatedInput","date-picker-label":"Date created","default-range-value":{
                  min: range.min,
                  max: range.max,
                }},on:{"handle:date-range-update":function($event){return _vm.handleRangeUpdate($event, refine)}}})]}}])})],1),_c('div',{ref:"video-save-filters",staticClass:"vms-video__filters--footer vd-background-lightest-grey vd-border-light-grey vd-padding-medium vd-text-align-right"},[_c('button',{staticClass:"vd-btn-small vd-btn-blue",attrs:{"id":"toggleVideoLibraryFiltersButton"},on:{"click":_vm.handleToggleVideoLibraryFilters}},[_vm._v(" Save Filters ")])])]),_c('div',{staticClass:"vms-video__library--section-right"},[_c('div',{staticClass:"vd-padding-20"},[_c('div',{staticClass:"vd-full-width vd-margin-bottom-medium"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-6"},[_c('ais-panel',[_c('ais-refinement-list',{attrs:{"attribute":"public_video"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refine = ref.refine;
return [_c('video-privacy',{attrs:{"items":items},on:{"handle:toggle-privacy":function($event){return refine(1)}}})]}}])})],1)],1),_c('div',{staticClass:"col-6"},[_c('video-view-type')],1)])]),_c('div',{staticClass:"vd-margin-bottom-medium"},[_c('ais-infinite-hits',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var items = ref.items;
                var refineNext = ref.refineNext;
                var sendEvent = ref.sendEvent;
return [(!items.length)?_c('no-results'):_c('div',[_c('video-thumbnail',{attrs:{"videos":items},on:{"handle:project-click":function($event){return sendEvent('conversion', $event, 'Item Project Clicked')},"handle:copy-url":function($event){return sendEvent('conversion', $event, 'Item Recommended')},"handle:vlp-click":function($event){return sendEvent('conversion', $event, 'Item VLP Clicked')},"handle:copy-private":function($event){return sendEvent('conversion', $event, 'Item Shared')},"handle:item-click":function($event){return sendEvent('click', $event, 'Item Clicked')}}}),_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(refineNext),expression:"refineNext"}]})],1)]}}])})],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }