var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-video__search vd-background-blue"},[_c('div',{class:[
      'vms-video__search--container vd-padding-left-20 vd-padding-right-20 vd-padding-top-50',
      _vm.isMobileScreen ? 'vd-padding-bottom-50' : 'vd-padding-bottom-90' ]},[_c('div',{class:[
        'vd-padding-bottom-30 vd-white',
        _vm.isMobileScreen ? 'vd-h2' : 'vd-h1' ]},[_c('strong',[_vm._v("Video Library")])]),_c('div',{staticClass:"row no-gutters vd-position-relative"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideDropDown),expression:"hideDropDown"}],staticClass:"vms-video__search--wrapper"},[_c('ais-search-box',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var refine = ref.refine;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchInput),expression:"searchInput"}],class:[
                _vm.isDropdownShown
                  ? 'vms-video__search--active'
                  : 'vd-border-radius-6',
                'vms-video__search--input vd-h4 vd-background-white vd-padding-top-10 vd-padding-bottom-10 vd-padding-right-small vd-padding-left-50' ],attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.searchInput)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.searchInput=$event.target.value},function($event){return refine($event.currentTarget.value)}],"click":_vm.showDropDown}})]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDropdownShown),expression:"isDropdownShown"}],class:[
            'vms-video__search--extendable vd-position-absolute vd-border-light-grey vd-background-white vd-full-width',
            _vm.isMobileScreen ? 'vd-margin-top-extra-small' : '' ]},[_c('div',{staticClass:"vd-position-relative vd-padding-left-30 vd-padding-right-30 vd-padding-top-20 vd-padding-bottom-20"},[_c('div',{staticClass:"vd-icon-close vd-position-absolute vms-video__search--icon-close",on:{"click":_vm.hideDropDown}}),_c('div',{staticClass:"row"},[_c('div',{class:[_vm.isMobileScreen ? 'col-12' : 'col-6']},[_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"product_type","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var items = ref.items;
          var refine = ref.refine;
          var searchForItems = ref.searchForItems;
return [_c('div',{staticClass:"vd-margin-bottom-20"},[_c('filters-list',{attrs:{"filters-list":items,"filter-type":"productTypes","filter-label":"Product type","filter-id-prefix":"productTypeFilterOption","allow-toggle-filter-list":false,"show-filter-list-by-default":true,"show-search-bar":false,"max-filter-display-size":_vm.refinementListLimit},on:{"handle:checkbox-click":function($event){return refine($event)},"handle:search-change":function($event){return searchForItems($event)}}})],1)]}}])})],1)],1),_c('div',{class:[_vm.isMobileScreen ? 'col-12' : 'col-6']},[_c('ais-range-input',{attrs:{"attribute":"project_price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var currentRefinement = ref.currentRefinement;
          var range = ref.range;
          var refine = ref.refine;
return [_c('range-min-max-slider',{attrs:{"default-range-value":{
                        min: range.min,
                        max: range.max,
                      },"initial-selected-range-value":{
                        min: range.min,
                        max: range.max,
                      },"current-refinement":currentRefinement,"allow-toggle-filter-list":false,"show-filter-list-by-default":true,"filter-label":"Project price","custom-input-container-class":"vms-video__filters--range","custom-input-range-class":"vms-video__filters--input-number"},on:{"handle:range-update":function($event){return _vm.handleRangeUpdate($event, refine)}}})]}}])}),_c('ais-range-input',{attrs:{"attribute":"project_production_hours"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var currentRefinement = ref.currentRefinement;
                      var range = ref.range;
                      var refine = ref.refine;
return [_c('range-min-max-slider',{attrs:{"default-range-value":{
                        min: range.min,
                        max: range.max,
                      },"initial-selected-range-value":{
                        min: range.min,
                        max: range.max,
                      },"current-refinement":currentRefinement,"allow-toggle-filter-list":false,"show-filter-list-by-default":true,"filter-label":"Project production hours","custom-input-container-class":"vms-video__filters--range","custom-input-range-class":"vms-video__filters--input-number"},on:{"handle:range-update":function($event){return _vm.handleRangeUpdate($event, refine)}}})]}}])}),_c('ais-panel',[_c('ais-refinement-list',{attrs:{"limit":_vm.refinementListLimit,"attribute":"aspect_ratio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var items = ref.items;
                      var refine = ref.refine;
return [_c('filters-list',{attrs:{"filters-list":items,"filter-label":"Aspect ratio","filter-id-prefix":"aspectRatioFilterOption","allow-toggle-filter-list":false,"allow-show-all":false,"show-filter-list-by-default":true,"show-search-bar":false,"max-filter-display-size":4},on:{"handle:checkbox-click":function($event){return refine($event)}}})]}}])})],1)],1)])])])],1),(_vm.isMobileScreen)?_c('button',{staticClass:"vms-video__search--btn-filter vd-border-width-0 vd-padding-top-small vd-padding-bottom-small vd-padding-right-medium vd-padding-left-medium vd-background-light-blue vd-border-light-blue vd-border-radius-5 vd-margin-left-extra-small",attrs:{"id":"toggleVideoLibraryFiltersButton"},on:{"click":_vm.toggleVideoLibraryFiltersShow}},[_c('span',{class:[
            'vms-video__search--btn-filter__icon',
            _vm.showVideoLibraryFilters
              ? 'vd-icon-close-white'
              : 'vd-icon-filter-white' ]})]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }