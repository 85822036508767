<template>
  <default-modal
    class="show"
    modal-dialog-class="modal-lg"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h3">Filter results by: {{ filterLabel }}</div>
      <div class="vms-modal__header--close close vd-black">
        <span id="closeVideoMoreDetailsSpan" @click="handleCloseButton">x</span>
      </div>
    </div>

    <div
      slot="modal-body"
      class="vms-video__preview--description-modal vd-padding-large"
    >
      <div class="vd-margin-bottom-small">
        <input
          v-if="showSearchBar"
          class="vd-full-width vd-border-light-grey vd-padding-small"
          type="text"
          placeholder="Search"
          @input="handleSearchBoxChange($event.currentTarget.value)"
        />
      </div>

      <div v-if="isFromSearch && !filters.length">No results found.</div>

      <div
        v-for="filter in filters"
        :key="filter.label"
        class="vms-video__filters--list"
      >
        <filter-checkbox
          id-prefix="filtersModal"
          :label="filter.label"
          :value="filter.label"
          :checked="filter.isRefined"
          :count="filter.count"
          :truncate-label="false"
          @handle:checkbox-click="handleCheckBoxClick(filter.value)"
        ></filter-checkbox>
      </div>
    </div>

    <div slot="modal-footer" class="vd-padding-large vd-text-align-center">
      <button
        class="vd-btn-medium vd-btn-grey vd-margin-small"
        @click="handleCloseButton"
      >
        Close
      </button>
    </div>
  </default-modal>
</template>

<script>
import { mapActions } from 'vuex'
import DefaultModal from '@components/modal/default-modal'
import FilterCheckbox from '@views/videos/video-filters/filter-checkbox'

export default {
  components: {
    DefaultModal,
    FilterCheckbox,
  },

  props: {
    filterLabel: {
      type: String,
      required: true,
      default: '',
    },

    filters: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },

    isFromSearch: {
      type: Boolean,
      require: false,
      default: false,
    },

    labelOption: {
      type: String,
      required: false,
      default: 'name',
    },

    showSearchBar: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  methods: {
    ...mapActions({
      toggleFilterListShowModal: 'videos/toggleFilterListShowModal',
    }),

    handleCheckBoxClick(value) {
      this.$emit('handle:checkbox-click', value)
    },

    handleSearchBoxChange(value) {
      this.$emit('handle:search-change', value)
    },

    handleCloseButton() {
      this.handleSearchBoxChange('')
      this.toggleFilterListShowModal()
    },
  },
}
</script>
