<template>
  <div class="vd-margin-top-10">
    <div v-for="(item, index) in items" :key="index">
      <div
        v-for="(refinement, refinementIndex) in item.refinements"
        :key="refinementIndex"
      >
        <div
          class="vd-display-inline-block vd-cursor-pointer vd-background-aqua vd-margin-bottom-10 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-10 vd-padding-right-10"
          @click.prevent="item.refine(refinement)"
        >
          <span class="vd-margin-right-10">{{
            processRefinementLabel(refinement)
          }}</span
          ><span>X</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import { parseInt, floor } from 'lodash'

export default {
  mixins: [FiltersMixin],

  props: {
    items: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },

  methods: {
    // eslint-disable-next-line complexity
    processRefinementLabel(refinement) {
      let label = ''

      switch (refinement.attribute) {
        case 'uploaded_timestamp': {
          label += this.changeOperatorToText(refinement.operator)
          label += this.$options.filters.unixToFormat(
            refinement.value,
            'DD MMM YYYY'
          )
          break
        }

        case 'project_shoot_crew_size': {
          label += refinement.label
          label += ' crew'
          break
        }

        case 'project_total_videos': {
          label += refinement.label
          label += ' videos'
          break
        }

        case 'project_price':
        case 'company_target': {
          label += this.changeOperatorToText(refinement.operator)
          label += this.$options.filters.moneyFormat(refinement.value)
          break
        }

        case 'project_production_hours':
        case 'project_preproduction_hours':
        case 'project_filming_hours':
        case 'project_editing_hours': {
          label += this.changeOperatorToText(refinement.operator)
          label += refinement.value
          label += ' hours'
          break
        }

        case 'duration': {
          label += this.changeOperatorToText(refinement.operator)
          label += this.formatDuration(refinement.value)
          break
        }

        case 'is_starred': {
          label = 'Hero videos'
          break
        }

        case 'favourite_users': {
          label = 'Favourite videos'
          break
        }

        default:
          label = refinement.label
          break
      }

      return label
    },

    formatDuration(duration) {
      if (duration < 120) {
        return `${duration} seconds`
      } else {
        let label = `${floor(parseInt(duration) / 60)} minutes`
        let seconds = parseInt(duration) % 60

        if (seconds > 0) {
          label += ` ${seconds} seconds`
        }

        return label
      }
    },

    changeOperatorToText(operator) {
      let label = ''

      switch (operator) {
        case '>=': {
          label += 'From '
          break
        }

        case '<=': {
          label += 'To '
          break
        }

        default:
          label = operator
          break
      }

      return label
    },
  },
}
</script>
